import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import ApplicationForm from "../components/careers/application-form";

export default function Careers({ data }) {
  const page = data.page;
  return (
    <Layout>
      <Seo seo={page.seo} />

      <div className="profile__heading__wrapper mt-6">
        <h3 className="profile__heading text-blue">{page.title}</h3>
      </div>

      <div className="wysiwyg__content__wrapper pt-2">
        <div
          className="wysiwyg__content"
          dangerouslySetInnerHTML={{ __html: page.content }}
        />
      </div>

      <div className="wysiwyg__content__wrapper mt-2">
        <div className="job__section--application">
          <div className="job__application__wrapper">
            <ApplicationForm jobTitle={page.title} />
          </div>
        </div>
      </div>

      <div className="mb-20" />
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!, $uri: String!) {
    page: wpPage(uri: { eq: $uri }) {
      title
      featuredImage {
        node {
          sourceUrl
        }
      }
      content
      seo {
        fullHead
      }
    }
    jobPosts: allWpJobPost(
      filter: { jobPost: { jobType: { elemMatch: { slug: { in: [$slug] } } } } }
    ) {
      nodes {
        title
        uri
        jobPost {
          jobContext
          jobProfile
          jobPurpose
          keyResponsibilities
          summary {
            directReport
            jobTitle
          }
        }
      }
    }
  }
`;
